//   A3: {value: '297 x 420 мм', label: 'А3 (297 х 420 мм)'},
//   A4: {value: '210 x 297 мм', label: 'А4 (210 х 297 мм)'},
//   A5: {value: '148 x 210 мм', label: 'А5 (148 х 210 мм)'},
//   A6: {value: '105 x 148 мм', label: 'А6 (105 х 148 мм)'},
//   A7: {value: '74 x 105 мм', label: 'А7 (74 х 105 мм)'},
const { flatten } = require("ramda")

const scale = (value, from, to) => {
  var scale = (to[1] - to[0]) / (from[1] - from[0])
  var capped = Math.min(from[1], Math.max(from[0], value)) - from[0]
  return capped * scale + to[0]
}

const roundNumber = (num, scale) => {
  if (!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale) + "e-" + scale)
  } else {
    var arr = ("" + num).split("e")
    var sig = ""
    if (+arr[1] + scale > 0) {
      sig = "+"
    }
    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale)
  }
}

const fE = E => roundNumber(scale(Math.min(4000, E), [1, 4000], [8, 1.6]), 2)

// Постеры/открытки
const calculateList = (type, data, papers, colors) => {
  const fFE = ([x, y], E) =>
    ({
      "297x420": E, // A3
      "210x297": E / 2, // A4
      "148x210": E / 4, // A5
      "105x148": E / 8, // A6
      "74x105": E / 16, // A6
    }[`${x}x${y}`])

  const fFPCC = ([x, y], P, CC) =>
    ({
      "297x420": 0, // A3
      "210x297": (P / 25) * CC, // A4
      "148x210": (P / 25) * 3 * CC, // A5
      "105x148": (P / 25) * 7 * CC, // A6
      "74x105": (P / 25) * 15 * CC, // A6
    }[`${x}x${y}`])

  // ————————————————
  // Стоимость печати
  // ————————————————
  const E = data.edition // Тираж
  const P = fFE(data.format, E) // Количество листов
  const IB = flatten(data.colors).reduce(
    (acc, color) => (color === "black" ? acc + 1 : acc),
    0
  ) // Количество мастеров black
  const IC = flatten(data.colors).reduce(
    (acc, color) => (color !== "black" ? acc + 1 : acc),
    0
  ) // Количество мастеров color
  const M = IB + IC + 2 // Количество мастер плёнок

  const MC = 84 // Стоимость мастер-плёнки

  const PC =
    data.paper[0] && data.paper[1]
      ? Number(
          papers
            .find(
              paper =>
                paper.title === data.paper[0] && paper.gsm === data.paper[1]
            )
            .price.replace(/,/, ".")
        ) // Стоимость листа бумаги
      : 10

  // const AI = 10 // Средняя стоимость краски на 1 лист
  const KOEFF = fE(E) // Коэффициент

  const MCF = M * MC * KOEFF // Финальная стоимость мастер плёнки
  const PCF = P * PC * 1.7 // Финальная стоимость бумаги
  const BIC = colors.find(color => color.value === "black").price / 2 // Стоимость чёрной краски
  const CIC = colors.find(color => color.value !== "black").price / 2 // Стоимость цветной краски
  // const ICF = ((P * IB * AI) + (P * IC * AI)) * KOEFF // Финальная стоимость краски
  const ICF = (P * IB * BIC + P * IC * CIC) * KOEFF // Финальная стоимость краски

  const F = MCF + PCF + ICF + 350 + M * 30 + P * 0.22 // + Запуск печати + Прожиг мастеров + Печать листов

  // ————————————————————
  // Стоимость постпечати
  // ————————————————————
  // const FC = 1.7 // Стоимость операции фальцовки
  const CC = 20 // Стоимость операции резки
  // const SC = 5 // Стоимость операции сшивки
  // const ASC = 10 // Стоимость сшивки на арж. скобу

  const FR = fFPCC(data.format, P, CC)

  console.log(
    `
    Тираж (E): ${E}
    Количество листов (P): ${P}
    Количество мастеров black (IB): ${IB}
    Количество мастеров color (IC): ${IC}
    Количество мастер плёнок (M): ${M}
    Стоимость мастер плёнки (MC): ${MC}
    Стоимость листа бумаги (PC): ${PC}
    Финальная стоимость бумаги (PCF): ${PCF}
    Коэффициент (KOEFF): ${KOEFF}
    Финальная стоимость мастер плёнки (MCF): ${MCF}
    Стоимость чёрной краски (BIC): ${BIC}
    Стоимость цветной краски (CIC): ${CIC}
    Финальная стоимость краски (ICF): ${ICF}
    Финальная стоимость печати (F): ${F}
    Финальная стоимость резки (FR): ${FR}
    `
  )

  const price = F + FR
  const allCost = Math.round((price * 100) / 93)

  return [allCost, roundNumber(allCost / E, 2)]
}

// Многостраничники
const calculateMultipages = (type, data, papers, colors) => {
  const fFEPG = ([x, y], E, PG) =>
    ({
      "297x420": PG * E, // A3
      "210x297": (PG / 4) * E, // A4
      "148x210": (PG / 8) * E, // A5
      "105x148": (PG / 16) * E, // A6
      "74x105": (PG / 32) * E, // A7
    }[`${x}x${y}`])

  const fM = ([x, y], PG, IB, IC) =>
    ({
      "297x420": PG * (IB + IC) + 1, // A3
      "210x297": Math.ceil(PG / 4) * (IB + IC) + 2, // A4
      "148x210": Math.ceil(PG / 8) * (IB + IC) + 2, // A5
      "105x148": Math.ceil(PG / 16) * (IB + IC) + 2, // A6
      "74x105": Math.ceil(PG / 32) * (IB + IC) + 2, // A7
    }[`${x}x${y}`])

  // ————————————————
  // Стоимость печати
  // ————————————————
  const E = data.edition // Тираж
  const PG = data.pages // Количество полос
  const P = fFEPG(data.format, E, PG) // Количество листов
  const IB =
    flatten(data.colors).reduce(
      (acc, color) => (color === "black" ? acc + 1 : acc),
      0
    ) * 2 // Количество цветов black
  const IC =
    flatten(data.colors).reduce(
      (acc, color) => (color !== "black" ? acc + 1 : acc),
      0
    ) * 2 // Количество цветов color
  const M = fM(data.format, PG, IB, IC) // Количество мастер-пленок
  const MC = 84 // Стоимость мастер-плёнки
  const PC =
    data.paper[0] && data.paper[1]
      ? Number(
          papers
            .find(
              paper =>
                paper.title === data.paper[0] && paper.gsm === data.paper[1]
            )
            .price.replace(/,/, ".")
        ) // Стоимость листа бумаги
      : 10
  const KOEFF = fE(E) // Коэффициент

  const MCF = M * MC * KOEFF // Финальная стоимость мастер плёнки
  const PCF = P * PC * 1.7 // Финальная стоимость бумаги
  const BIC = colors.find(color => color.value === "black").price / 2 // Стоимость чёрной краски
  const CIC = colors.find(color => color.value !== "black").price / 2 // Стоимость цветной краски
  const ICF = (P * IB * BIC + P * IC * CIC) * KOEFF // Финальная стоимость краски
  const F = MCF + PCF + ICF + 350 + M * 30 + P * 0.4 // + Запуск печати + Прожиг мастеров + Печать листов

  // ————————————————————
  // Стоимость постпечати
  // ————————————————————
  const FC = 2.5 // Стоимость операции фальцовки
  const CC = 30 // Стоимость операции резки
  const SC = 8 // Стоимость операции сшивки
  const ASC = 15 // Стоимость сшивки на арх. скобу
  const PD = 2 // Стоимость операции подборки

  const fFPECC = ([x, y], P, E, CC) =>
    ({
      "210x297": ((P / 25) * 0 + E) * CC, // A4
      "148x210": ((P / 25) * 1 + E) * CC, // A5
      "105x148": ((P / 25) * 3 + E) * CC, // A6
      "74x105": ((P / 25) * 7 + E) * CC, // A7
    }[`${x}x${y}`])

  const FR = fFPECC(data.format, P, E, CC)
  const FST = E * 2 * (data.brace === "Архивная скоба внакидку" ? ASC : SC)
  const Ffold = E * FC
  const Fcomp = (PG / 4) * E * PD

  const price = F + FR + FST + Ffold + Fcomp

  console.log(
    `
    Тираж (E): ${E}
    Количество полос (PG): ${PG}
    Количество листов (P): ${P}
    Количество мастеров black (IB): ${IB}
    Количество мастеров color (IC): ${IC}
    Количество мастер плёнок (M): ${M}
    Стоимость мастер плёнки (MC): ${MC}
    Стоимость листа бумаги (PC): ${PC}
    Финальная стоимость бумаги (PCF): ${PCF}
    Коэффициент (KOEFF): ${KOEFF}
    Финальная стоимость мастер плёнки (MCF): ${MCF}
    Стоимость чёрной краски (BIC): ${BIC}
    Стоимость цветной краски (CIC): ${CIC}
    Финальная стоимость краски (ICF): ${ICF}
    Финальная стоимость печати (F): ${F}
    Финальная стоимость резки (FR): ${FR}
    `
  )

  const allCost = Math.round((price * 100) / 93)

  return [allCost, roundNumber(allCost / E, 2)]
}

module.exports = (type, data, papers, colors) =>
  type === "multipages"
    ? calculateMultipages(type, data, papers, colors)
    : calculateList(type, data, papers, colors)
