import React from 'react'
import {Link} from 'gatsby'
import cx from 'classnames'
import styles from './Footer.module.css'

export const icons = {
  facebook: (
    <svg viewBox="0 0 26 26">
      <path d="M26 4.334C26 2.057 23.944 0 21.667 0H4.333C2.056 0 0 2.057 0 4.334v17.332C0 23.943 2.056 26 4.334 26H13v-9.822H9.822v-4.334H13v-1.688c0-2.912 2.186-5.534 4.875-5.534h3.503v4.334h-3.503c-.383 0-.83.465-.83 1.162v1.726h4.333v4.334h-4.334V26h4.623C23.944 26 26 23.943 26 21.666V4.334z"/>
    </svg>
  ),
  instagram: (
    <svg viewBox="0 0 26 26">
      <path d="M18.825 0H7.175C3.219 0 0 3.219 0 7.175v11.65C0 22.78 3.219 26 7.175 26h11.65C22.78 26 26 22.781 26 18.825V7.175C26 3.22 22.781 0 18.825 0zm4.868 18.825a4.874 4.874 0 01-4.868 4.868H7.175a4.874 4.874 0 01-4.868-4.868V7.175a4.874 4.874 0 014.868-4.868h11.65a4.874 4.874 0 014.868 4.868v11.65z"/>
      <path d="M13 6.19A6.817 6.817 0 006.19 13 6.817 6.817 0 0013 19.81 6.817 6.817 0 0019.81 13 6.817 6.817 0 0013 6.19zm0 11.275A4.47 4.47 0 018.535 13 4.47 4.47 0 0113 8.535 4.47 4.47 0 0117.465 13 4.47 4.47 0 0113 17.465zm7.429-13.751c-.488 0-.968.198-1.312.544a1.866 1.866 0 00-.546 1.313c0 .489.2.968.546 1.314.344.344.824.544 1.312.544.49 0 .967-.2 1.313-.544.346-.346.544-.826.544-1.314 0-.49-.198-.969-.544-1.313a1.865 1.865 0 00-1.313-.544z"/>
    </svg>
  ),
  vk: (
    <svg viewBox="0 0 30 18">
      <path d="M29.733 15.437c-.081-.136-.58-1.232-2.98-3.484-2.513-2.36-2.175-1.976.851-6.054 1.844-2.484 2.58-4 2.35-4.65-.22-.617-1.575-.454-1.575-.454L23.87.82s-.334-.045-.583.104c-.242.148-.398.49-.398.49s-.714 1.922-1.667 3.556c-2.008 3.45-2.812 3.631-3.14 3.417-.764-.499-.573-2.006-.573-3.075 0-3.343.501-4.736-.976-5.097-.492-.12-.852-.199-2.106-.212-1.61-.017-2.972.006-3.743.387-.514.254-.91.82-.668.853.299.04.974.184 1.332.677.463.635.446 2.065.446 2.065s.266 3.934-.62 4.423c-.61.336-1.444-.349-3.235-3.48-.917-1.601-1.611-3.374-1.611-3.374s-.133-.33-.371-.506C5.669.834 5.264.766 5.264.766L.977.793s-.643.019-.88.301c-.21.252-.016.772-.016.772s3.357 7.938 7.156 11.94c3.485 3.67 7.442 3.429 7.442 3.429h1.793s.542-.06.817-.362c.255-.277.246-.797.246-.797s-.036-2.432 1.082-2.79c1.1-.353 2.514 2.35 4.013 3.391 1.132.787 1.993.615 1.993.615l4.007-.057s2.096-.131 1.103-1.798z"/>
    </svg>
  ),
  esh: (
    <svg viewBox="0 0 45 50">
      <path d="M32 0l-9.03 23.011 8.19-5.113L11 49.218l9.24-21.732-8.4 5.753L32 0zM0 21.198l9-6.432 4.385 6.647-8.539 4.502v4.932l1.846.858L15 25.7 5.538 36.42 0 34.064V21.198zm4.846-1.93v5.794l3.462-2.897-3.462-2.897zM38.125 7.875v13.224L45 13.332v17.842l-4.321 3.359V20.469l-2.554 1.68v10.705L34 36.422V11.233l4.125-3.358z" fillRule="evenodd"/>
    </svg>
  ),
  shop: (
    <svg viewBox="0 0 149 29">
      <path d="M0 27.724h18.771V19.96H8.607v-2.14h10.164v-6.877H8.585V8.802h10.186V.822H0zm51.469-17.646h-2.487V.822h-9.407v26.902h9.407v-7.828h2.487v7.828h9.385V.822H51.47zm-21.929.172v-.302h8.845l-.13-1.19C37.738 3.676 33.975.13 29.087.13c-5.341 0-9.515 4-9.515 9.104 0 5.817 3.352 9.213 9.213 9.364v.303h-9.408l.13 1.19c.54 4.908 4.585 8.325 9.861 8.325 5.277 0 9.58-4.174 9.58-9.299-.021-4.823-4.216-8.758-9.407-8.866zm64.618-.172H91.67V.8h-9.407v26.902h9.407v-7.806h2.487v7.828h9.407V.8h-9.407v9.278zm2.14-7.137h5.104v22.62H96.3v-7.828h-6.77v7.828h-5.103V2.941h5.104v9.256H96.3V2.94zM139.312.065c-5.147 0-9.018 3.784-9.018 8.801v18.815h10.142v-9.386c5.623-.497 8.564-4.887 8.564-9.212 0-5.06-4.239-9.018-9.688-9.018zm.065 16.11h-1.082v9.343h-5.86V8.866c0-3.806 2.963-6.66 6.877-6.66 4.303 0 7.547 2.963 7.547 6.877 0 4.67-3.763 7.093-7.482 7.093zM72.035 10.25v-.302h8.845l-.13-1.19C80.252 3.676 76.468.13 71.58.13c-5.341 0-9.515 4-9.515 9.104 0 5.817 3.352 9.213 9.213 9.364v.303H61.87l.13 1.19c.54 4.908 4.584 8.325 9.86 8.325 5.277 0 9.58-4.174 9.58-9.299-.02-4.823-4.216-8.758-9.406-8.866zm-.173 16.025c-2.076 0-3.915-.627-5.32-1.817a6.87 6.87 0 01-2.184-3.416h9.06V16.5l-2.097-.065c-2.336-.064-4.13-.713-5.32-1.924-1.19-1.211-1.795-2.985-1.795-5.299 0-3.892 3.244-6.963 7.375-6.963 1.838 0 3.481.627 4.757 1.838 1.038.973 1.73 2.228 2.076 3.698h-8.542v4.563l2.098.043c4.022.087 7.288 3.093 7.288 6.726.021 3.936-3.31 7.158-7.396 7.158zM116.93.065c-6.726 0-12.22 5.471-12.22 12.218v3.915c0 6.725 5.472 12.218 12.22 12.218 6.746 0 12.218-5.471 12.218-12.218v-3.936c0-6.726-5.493-12.197-12.219-12.197zm10.077 16.11c0 5.559-4.52 10.078-10.078 10.078-5.557 0-10.077-4.52-10.077-10.077v-3.914c0-5.558 4.52-10.078 10.077-10.078 5.558 0 10.078 4.52 10.078 10.078v3.914z"/>
    </svg>
  ),
  download: (
    <svg viewBox="0 0 20 20">
      <path d="M16.007 10.879L10 16.886 3.993 10.88l1.105-1.105 4.12 4.121V0h1.563v13.895l4.121-4.121 1.105 1.105zM20 18.438H0V20h20v-1.563z"/>
    </svg>
  ),
}

export const Footer = ({pathname}) => (
  <footer className={cx(styles.root, {[styles.noborder]: pathname === '/'})}>
    <div className={styles.block}>

      <a href="/eshprint_guide_2019.pdf" target="_blank" rel="noopener noreferrer" className={styles.pdf}>Руководство к печати {icons.download}</a>
    </div>

    <div className={styles.block}>
      <a href="https://goo.gl/maps/ZcXv1BvafGZkpZUW9" target="_blank" rel="noopener noreferrer">4-й Сыромятнический пер.,<br/>1/8 строение 6</a><br/>
      <a href="tel:+79030160317">+7 (903) 016-03-17</a><br/>
      <a href="mailto:inbox@eshprint.com">inbox@eshprint.com</a>
    </div>

    <div className={styles.block}>
      <div><Link to="/ecology/" activeClassName={styles.active}>Экологичность</Link></div>
      <div><Link to="/legal/" activeClassName={styles.active}>Реквизиты</Link></div>
      <div><Link to="/policy/" activeClassName={styles.active}>Пользовательское соглашение</Link></div>
    </div>

    <div className={styles.block}>
      <a href="https://shop.eshprint.com/" target="_blank" rel="noopener noreferrer">{icons.shop}</a>
      <a href="https://eshgruppa.com/" target="_blank" rel="noopener noreferrer">{icons.esh}</a>
    </div>
  </footer>
)

export default Footer
