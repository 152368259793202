// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-calculator-page-index-js": () => import("./../../../src/templates/calculator-page/index.js" /* webpackChunkName: "component---src-templates-calculator-page-index-js" */),
  "component---src-templates-ecology-page-index-js": () => import("./../../../src/templates/ecology-page/index.js" /* webpackChunkName: "component---src-templates-ecology-page-index-js" */),
  "component---src-templates-index-page-index-js": () => import("./../../../src/templates/index-page/index.js" /* webpackChunkName: "component---src-templates-index-page-index-js" */),
  "component---src-templates-legal-page-index-js": () => import("./../../../src/templates/legal-page/index.js" /* webpackChunkName: "component---src-templates-legal-page-index-js" */),
  "component---src-templates-policy-page-index-js": () => import("./../../../src/templates/policy-page/index.js" /* webpackChunkName: "component---src-templates-policy-page-index-js" */),
  "component---src-templates-portfolio-page-index-js": () => import("./../../../src/templates/portfolio-page/index.js" /* webpackChunkName: "component---src-templates-portfolio-page-index-js" */),
  "component---src-templates-workshops-page-index-js": () => import("./../../../src/templates/workshops-page/index.js" /* webpackChunkName: "component---src-templates-workshops-page-index-js" */)
}

