import axios from "axios"
import cyrillicToTranslit from "cyrillic-to-translit-js"

const submit = data => axios.post("/fn/submit", data)

const scale = (value, from, to) => {
  var scale = (to[1] - to[0]) / (from[1] - from[0])
  var capped = Math.min(from[1], Math.max(from[0], value)) - from[0]
  return capped * scale + to[0]
}

const uploadFile = async (file, folderName, onProgress) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onabort = err => reject(err)
    reader.onerror = err => reject(err)
    reader.onload = () =>
      axios
        .post("https://content.dropboxapi.com/2/files/upload", reader.result, {
          headers: {
            // @TODO Move token to .env
            Authorization:
              "Bearer gortt78NXpEAAAAAAADcGhgUjEiBkx4hhiYOQGsu5T6lysTu8vxp3TPYLqfrPc5W",
            "Content-Type": "application/octet-stream",
            "Dropbox-API-Arg": JSON.stringify({
              path: cyrillicToTranslit().transform(
                `/${folderName}/${file.name}`
              ),
              mode: "add",
              autorename: true,
              mute: false,
              strict_conflict: false,
            }),
          },
          onUploadProgress: ev =>
            onProgress(scale((ev.loaded / ev.total) * 100, [0, 100], [0, 80])),
        })
        .then(res =>
          axios.post(
            "https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings",
            {
              path: res.data.path_display,
              settings: { requested_visibility: "public" },
            },
            {
              headers: {
                Authorization:
                  "Bearer gortt78NXpEAAAAAAADcGhgUjEiBkx4hhiYOQGsu5T6lysTu8vxp3TPYLqfrPc5W",
                "Content-Type": "application/json",
              },
              onUploadProgress: ev =>
                onProgress(
                  scale((ev.loaded / ev.total) * 100, [0, 100], [80, 100])
                ),
            }
          )
        )
        .then(res => resolve(res))
  })

export { submit, uploadFile }
