export const SET_TYPE = 'SET_TYPE'
export const SET_STEP = 'SET_STEP'
export const SET_DATA = 'SET_DATA'
export const SET_STAGE = 'SET_STAGE'
export const SUBMIT = 'SUBMIT'
export const SET_PENDING = 'SET_PENDING'
export const FINISH = 'FINISH'
export const RESET = 'RESET'
export const RESET_CHECKOUT = 'RESET_CHECKOUT'
export const SET_FILE_LOADING = 'SET_FILE_LOADING'
export const ERROR = 'ERROR'

export const FILE_SET = 'FILE_SET'
export const FILE_REMOVE = 'FILE_REMOVE'
