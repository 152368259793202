import React from 'react'
import cx from 'classnames'
import T from 'prop-types'
import {Link} from 'gatsby'
import styles from './Header.module.css'

export const logo = (
  <svg viewBox="0 0 752 132">
    <path d="M.7 128.3h86.7V92.4h-47v-9.9h47V50.6h-47v-9.9h47V3.9H.7v124.4zm237.9-81.6h-11.5V3.9h-43.4v124.4h43.4V92.1h11.5v36.2h43.5V3.9h-43.5v42.8zm216.9 78l-44.1-39.9c23.7-1.8 39.4-18.5 39.4-42.6 0-23-19.2-41.8-42.8-41.8-10.8 0-21.6 4.1-29.6 11.2-3.8 3.4-6.9 7.3-9 11.6C362.1 9.6 347.1.4 329.3.4c-23.8 0-41.7 17.5-41.7 40.7v87h46.9V84.7c13.8-1.2 24.2-7.5 30.8-16.1v59.6h139.5V3.7h-49.3v121zM329.5 75h-5v43.2h-27.1V41.1c0-17.6 13.7-30.8 31.8-30.8 19.9 0 34.9 13.7 34.9 31.8 0 21.6-17.4 32.9-34.6 32.9zm45.7 43.2V40.1c0-16.1 15-29.8 32.8-29.8 18.1 0 32.8 14.3 32.8 31.8 0 19.9-13.3 32.8-33.8 32.8h-21.2l47.8 43.3h-58.4zm90.2-104.6h29.5v104.6h-29.5V13.6zm123.8 32.5l-26.3-33.6-.2-.3c-5.7-6.3-13.9-10-22.4-10-16.6 0-30.1 13.5-30.1 30.1v95.6h46.3V85.3l26.3 33.5.2.2c5.7 6.3 13.9 10 22.4 10 16.6 0 30.1-13.5 30.1-30.1V3.5h-46.3v42.6zm9.9-32.7h26.4V99c0 11.1-9 20.2-20.2 20.2-5.7 0-11.1-2.4-14.9-6.6l-43.9-55.9V118h-26.4V32.3c0-11.1 9-20.2 20.2-20.2 5.7 0 11.1 2.4 14.9 6.6l43.9 56.2V13.4zm41.4-9.8v84.3l31-27.6V128h49.4V60.3l31 27.6V3.6H640.5zM742 65.8l-31-27.6v79.9h-29.6V38.2l-31 27.6V13.6H742v52.2zM137.3 47.5v-1.4h40.9l-.6-5.5C175.2 17.1 157.8.7 135.2.7c-24.7 0-44 18.5-44 42.1 0 26.9 15.5 42.6 42.6 43.3v1.4H90.3l.6 5.5c2.5 22.7 21.2 38.5 45.6 38.5 24.4 0 44.3-19.3 44.3-43-.1-22.4-19.5-40.5-43.5-41z"/>
  </svg>
)

export const Header = ({siteTitle, pathname, ...props}) => (
  <header className={cx(styles.root, {[styles.shrinked]: pathname !== '/'})}>
    <div className={styles.container}>
      <Link to="/" className={styles.logo}>{logo}</Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: T.string,
  pathname: T.string.isRequired,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
