import * as types from './types'
import * as api from 'api'
import {omit} from 'ramda'
import * as calculator from './calculator'

export const initialState = {} // {[id]: {name: '', progress: 0, error: undefined}}

export const actions = {
  set: (id, name, progress) => ({type: types.FILE_SET, id, name, progress}),
  remove: id => ({type: types.FILE_REMOVE, id}),
  upload: (id, file, folderName, color) => async (dispatch, getState) => {
    if (!file) return
    if ((file.size / 1024 / 1024) > 500) {
      dispatch({type: types.FILE_SET, id, name: file.name, progress: 0, error: 'Размер файла не может превышать 500mb'})
      return
    }
    dispatch({type: types.FILE_SET, id, name: file.name, progress: 0})

    let result
    try {
      result = await api.uploadFile(file, folderName, progress => {
        const f = getState().files[id]

        if (f && f.name === file.name) {
          dispatch({type: types.FILE_SET, id, name: file.name, progress})
        }
      })
    } catch(err) {
      if (window.Sentry) {
        window.Sentry.captureException(err)
      }
      dispatch({type: types.FILE_SET, id, name: file.name, progress: 0, error: 'Что-то пошло не так при загрузке файла. Попробуйте его перезагрузить.'})
      return
    }

    if (result.data.url) {
      const f = getState().files[id]

      if (f && f.name === file.name) {
        const fs = getState().calculator.data.files

        if (id === 'all-colors') {
           dispatch(calculator.actions.setData('files', [fs[0], fs[1], result.data.url]))
        } else if (id[0] === '0') {
           dispatch(calculator.actions.setData('files', [{...fs[0], [color]: result.data.url}, fs[1], fs[2]]))
        } else if (id[0] === '1') {
           dispatch(calculator.actions.setData('files', [fs[0], {...fs[1], [color]: result.data.url}, fs[2]]))
        }
      }
    }
  },
}

export const selectors = {
  file: (state, id) => state.files[id],
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILE_SET:
      return action.progress === 0 ?
        ({...state, [action.id]: {name: action.name, progress: action.progress, error: action.error}}) :
        state[action.id] ?
          ({...state, [action.id]: {name: action.name, progress: action.progress, error: action.error}}) :
          state

    case types.FILE_REMOVE:
      return omit([action.id], state)

    case types.RESET:
      return initialState

    default:
      return state
  }
}

export default reducer
