import React from 'react'
import T from 'prop-types'
import Header from './Header'
import Footer from './Footer'
import Navigation from './Navigation'
import './Layout.css'

export const Layout = ({children, location = {}}) => {
  return (
    <>
      <Header pathname={location.pathname}/>
      <section className="root">
        {location.pathname === '/' ? null : <Navigation/>}
        {children}
      </section>
      <Footer pathname={location.pathname}/>
    </>
  )
}

Layout.propTypes = {
  children: T.node.isRequired,
  location: T.shape({
    pathname: T.string.isRequired,
  }).isRequired,
}

export default Layout
