const Joi = require("@hapi/joi")
const { flatten, uniq } = require("ramda")

const format = (exports.format = Joi.array()
  .items(
    Joi.number()
      .integer()
      .required()
  )
  .length(2)
  .required())
const edition = (exports.edition = Joi.number()
  .integer()
  .greater(24)
  .message("Минимальный тираж 25 копий")
  .less(10000)
  .message("Максимальный тираж 10000 копий")
  .positive()
  .required())
const pages = (exports.pages = Joi.number()
  .integer()
  .greater(3)
  .less(1000)
  .positive()
  .required())
const brace = (exports.brace = Joi.string()
  .trim()
  .required())
const paper = (exports.paper = Joi.array()
  .items(Joi.string().trim(), Joi.number().integer(), Joi.any())
  .sparse(false)
  .length(2))

exports.projectComment = Joi.string().trim()
const isColorProofs = (exports.isColorProofs = Joi.boolean().required())
const title = (exports.title = Joi.string()
  .trim()
  .min(3)
  .required())

// @TODO 🤔
const regexps = {
  url: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
  tel: /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/,
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}
exports.files = {
  validate: (files, colors) =>
    Object.keys(files[0]).length === colors[0].length &&
    Object.keys(files[0]).every(key =>
      Boolean(regexps.url.test(files[0][key] || ""))
    ) &&
    Object.keys(files[1]).length === colors[1].length &&
    Object.keys(files[1]).every(key =>
      Boolean(regexps.url.test(files[1][key] || ""))
    ) &&
    (uniq(flatten(colors)).length === 1
      ? true
      : Boolean(regexps.url.test(files[2] || ""))),
}

exports.colors = { validate: colors => !(colors[0].length > 0) }

const delivery = (exports.delivery = Joi.string()
  .trim()
  .required())
const contacts = {
  name: Joi.string()
    .trim()
    .min(3)
    .required(),
  tel: Joi.string()
    .trim()
    .pattern(regexps.tel)
    .required(),
  email: Joi.string()
    .trim()
    .pattern(regexps.email)
    .required(),
  city: Joi.string()
    .trim()
    .min(3)
    .required(),
  address: Joi.string()
    .trim()
    .min(3)
    .required(),
  flat: Joi.string()
    .trim()
    .min(3)
    .required(),
  index: Joi.string()
    .trim()
    .min(3)
    .required(),
}
contacts.all = {
  pickup: Joi.object({
    name: contacts.name,
    tel: contacts.tel,
    email: contacts.email,
  })
    .unknown()
    .required(),
  courier: Joi.object({
    name: contacts.name,
    tel: contacts.tel,
    email: contacts.email,
    city: contacts.city,
    address: contacts.address,
    flat: contacts.flat,
    index: contacts.index,
  })
    .unknown()
    .required(),
}
exports.contacts = contacts

const calculations = (exports.calculations = Joi.array()
  .items(Joi.number().required())
  .length(2)
  .required())

exports.types = {}
exports.types.posters = exports.types.postcards = Joi.object({
  format,
  edition,
  paper,
  isColorProofs,
  title,
  delivery,
  calculations,
})
  .unknown()
  .required()
exports.types.multipages = Joi.object({
  format,
  edition,
  pages,
  brace,
  paper,
  isColorProofs,
  title,
  delivery,
  calculations,
})
  .unknown()
  .required()
