import * as calculator from './calculator'
import * as files from './files'

export const reducers = {
  calculator: calculator.reducer,
  files: files.reducer,
}

export const selectors = {
  calculator: calculator.selectors,
  files: files.selectors,
}

export const actions = {
  calculator: calculator.actions,
  files: files.actions,
}
