import React from 'react'
import {Link} from 'gatsby'
import styles from './Navigation.module.css'

export const links = [
  ['Портфолио', '/portfolio/'],
  ['Воркшопы', '/workshops/'],
];

export const Navigation = () => (
  <ul className={styles.root}>
    {links.map(([title, to]) => (
      <li key={to} className={styles.wrap}>
        <Link to={to} className={styles.link} activeClassName={styles.active}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
)

export default Navigation
