import * as api from 'api'
import nanoid from 'nanoid'
import * as types from './types'
import {format} from 'date-fns'
import {always, assoc, __, path} from 'ramda'
import * as utils from 'utils'

const generateFolderName = () => `${format(new Date(), 'dd.MM.yy-HH:mm')}_${nanoid(5)}`

export const initialState = {
  stage: 'initial', // initial | parameters | checkout
  step: 'format', // (format | edition | pages | brace | paper | colors | extra | summary) | (title | files | delivery | contacts | extra)
  type: null, // posters | postcards | multipages
  error: null,
  isFinished: false,
  isPending: false,
  folderName: generateFolderName(),
  filesLoading: {},

  data: {
    format: [null, null], // [width, height]
    edition: null,
    pages: null,
    paper: [null, null], // [type, gsm]
    brace: null,
    colors: [[], []],
    projectComment: '',
    isColorProofs: false,
    title: '',
    files: [{}, {}, ''],
    delivery: null,
    contacts: {
      name: '',
      tel: '',
      email: '',
      city: '',
      address: '',
      flat: '',
      index: '',
    },
    extra: '',
  },
}

export const actions = {
  setType: assoc('value', __, {type: types.SET_TYPE}),
  setStep: assoc('value', __, {type: types.SET_STEP}),
  setStage: assoc('value', __, {type: types.SET_STAGE}),
  setData: (key, value) => ({type: types.SET_DATA, key, value}),
  reset: always({type: types.RESET}),
  resetCheckout: always({type: types.RESET_CHECKOUT}),
  submit: (papers, colors) => async (dispatch, getState) => {
    dispatch({type: types.SET_PENDING, value: true})
    const {calculator} = getState()
    try {
      await api.submit({...calculator.data, type: calculator.type, calculations: utils.calculate(calculator.type, calculator.data, papers, colors)})
    } catch (err) {
      if (window.Sentry) {
        window.Sentry.captureException(err)
      }
      console.error(err)
      return dispatch({type: types.ERROR, value: err})
    }
    dispatch({type: types.FINISH})
  },
}

export const selectors = {
  stage: path(['calculator', 'stage']),
  type: path(['calculator', 'type']),
  step: path(['calculator', 'step']),
  data: path(['calculator', 'data']),
  error: path(['calculator', 'error']),
  isPending: path(['calculator', 'isPending']),
  isFinished: path(['calculator', 'isFinished']),
  filesLoading: path(['calculator', 'filesLoading']),
  folderName: path(['calculator', 'folderName']),
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_STAGE:
      return ({...state, stage: action.value})

    case types.SET_TYPE:
      return ({...state, type: action.value})

    case types.SET_STEP:
      return ({...state, step: action.value})

    case types.SET_DATA:
      return ({...state, data: {...state.data, [action.key]: action.value}})

    case types.SET_PENDING:
      return ({...state, isPending: action.value})

    case types.RESET_CHECKOUT:
      return ({...state, stage: 'parameters', step: 'summary'})

    case types.SET_FILE_LOADING:
      return ({...state, filesLoading: {...state.filesLoading, [action.key]: action.value}})

    case types.FINISH:
      return ({...initialState, isFinished: true})

    case types.ERROR:
      return ({...state, isFinished: true, error: action.value})

    case types.RESET:
      return ({...initialState, folderName: generateFolderName()})

    default:
      return state
  }
}

export default reducer
